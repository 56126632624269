import React from 'react'
import * as styles from './PreviewList.module.scss'
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { stripHtml } from '../utils'

interface PreviewListProps {
  entries: {
    image: IGatsbyImageData;
    title: string;
    text?: string | React.ReactNode;
  }[];
}

const PreviewList = ({ entries }: PreviewListProps) => {
  return (
    <div className={styles.container}>
      {entries.map((entry, index) => {
        const entryImage = entry.image && getImage(entry.image)
        return (
          <div key={index} className={styles.entry}>
            {entryImage && (
              <div className={styles.image}>
                <GatsbyImage
                  image={entryImage}
                  alt={entry.title}
                />
              </div>
            )}
            <div className={styles.content}>
              <div className={styles.title}>{entry.title}</div>
              <div>
                {typeof entry.text === 'string' ? stripHtml(entry.text).substring(0, 120)+'…' : entry.text}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PreviewList
