import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import * as styles from './Home.module.scss'
import LinkArrow from '../assets/link-arrow.svg'
import Button from "../components/Button"
import ROUTES from "../components/routes"
import { formatDateRange, sortByDateProperty } from "../utils"
import PreviewList from "../components/PreviewList"
import DateIcon from '../assets/date.svg'
import YoutubeVideo from "../components/YoutubeVideo"
import { useIntl, Link } from "gatsby-plugin-intl"

type HomeQueryProps = PageQuery<{
  hero: HeroImageData<IGatsbyImageData>;
  title: string;
  body: MarkdownField;
  portrait: IGatsbyImageData;
  video?: string;
  linktiles: {
    image: IGatsbyImageData;
    link: string;
    text: string;
    title: string;
  }[];
  leistungenHead: string;
  leistungenText: MarkdownField;
  leistungenButton: string;
  newsHead: string;
  newsButton: string;
  kurseHead: string;
  kurseButton: string;
}> & {
  unterschrift: IGatsbyImageData;
  newsMarkdown: {
    frontmatter: Record<string, {
      news: {
        image: IGatsbyImageData;
        title: string;
        date: string;
        text: MarkdownField;
      }[]
    }>;
  };
  kurseMarkdown: {
    frontmatter: Record<string, {
      kurse: {
        image: IGatsbyImageData;
        title: string;
        start: string;
        end: string;
      }[]
    }>;
  }
}

const IndexPage = ({ data }: PageProps<HomeQueryProps>) => {
  const { locale } = useIntl()
  const { markdownRemark, newsMarkdown, kurseMarkdown, unterschrift } = data
  const frontmatter = markdownRemark.frontmatter[locale]!
  const newsFrontmatter = newsMarkdown.frontmatter[locale]!
  const kurseFrontmatter = kurseMarkdown.frontmatter[locale]!
  const portraitImage = getImage(frontmatter.portrait)
  const unterschriftImage = getImage(unterschrift)

  return (
    <Layout hero={frontmatter.hero} seo={{title: '', description: frontmatter.body.html}}>
      <div className={styles.intro}>
        <div className={styles.text}>
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{__html: frontmatter.body.html}}></div>
          {unterschriftImage && <GatsbyImage image={unterschriftImage} alt='' />}
          <div>Arnold Drachenberg</div>
        </div>
        {portraitImage && <GatsbyImage image={portraitImage} alt='Portrait Arnold Drachenberg' className={styles.portrait} />}
      </div>
      {frontmatter.video && <YoutubeVideo videoIdOrLink={frontmatter.video} fullWidth />}
      <div className={styles.linkTiles}>
        {frontmatter.linktiles.map(tile => {
          const image = getImage(tile.image)
          const isExternal = tile.link.startsWith('http')
          const tileContent = (<>
            {image && <GatsbyImage image={image} alt={tile.title} />}
            <div className={styles.bottom}>
              <div className={styles.title}>{tile.title}</div>
              <div className={styles.text}>{tile.text}</div>
              <LinkArrow className={styles.arrow} />
            </div>
          </>)

          if (isExternal) {
            return <a
              key={tile.link}
              href={tile.link}
              target={'_blank'}
            >
              {tileContent}
            </a>
          } else {
            return <Link
              key={tile.link}
              to={tile.link}
            >
              {tileContent}
            </Link>
          }

        })}
      </div>
      <h2>{frontmatter.leistungenHead}</h2>
      <div dangerouslySetInnerHTML={{__html: frontmatter.leistungenText.html}}></div>
      <Button icon='link' iconPosition='end' href={ROUTES.LEISTUNGEN}>{frontmatter.leistungenButton}</Button>
      <h2>{frontmatter.newsHead}</h2>
      <PreviewList entries={sortByDateProperty(newsFrontmatter.news, 'date').slice(0, 3).map(n => ({...n, text: n.text.html}))}/>
      <Button icon='link' iconPosition='end' href={ROUTES.NEWS}>{frontmatter.newsButton}</Button>
      <h2>{frontmatter.kurseHead}</h2>
      <PreviewList entries={kurseFrontmatter.kurse.slice(0, 3).map(kurs => {
        return {
          ...kurs,
          text: (<div style={{display: 'flex'}}>
            <DateIcon style={{marginRight: '.5rem'}} />
            {formatDateRange(kurs.start, kurs.end)}
          </div>)
        }
      })}/>
      <Button icon='link' iconPosition='end' href={ROUTES.DENTAL_KURSE}>{frontmatter.kurseButton}</Button>
    </Layout>
  )
}

export const pageQuery = graphql`
  fragment HomeFragment on FrontmatterLocalized {
    hero {
      image {
        desktopHero: childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 2.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
        mobileHero: childImageSharp {
          gatsbyImageData(
            width: 760
            aspectRatio: 1.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
      }
    }
    title
    body {
      html
    }
    portrait {
      childImageSharp {
        gatsbyImageData(
          width: 240
          placeholder: BLURRED
        )
      }
    }
    video
    linktiles {
      image {
        childImageSharp {
          gatsbyImageData(
            width: 350
            aspectRatio: 1.5
            placeholder: BLURRED
          )
        }
      }
      link
      text
      title
    }
    leistungenHead
    leistungenText {
      html
    }
    leistungenButton
    newsHead
    newsButton
    kurseHead
    kurseButton
  }

  fragment HomeNewsFragment on FrontmatterLocalized {
    news {
      image {
        childImageSharp {
          gatsbyImageData(
            width: 100
            aspectRatio: 1.5
            placeholder: BLURRED
          )
        }
      }
      title
      date
      text {
        html
      }
    }
  }

  fragment HomeKurseFragment on FrontmatterLocalized {
    kurse {
      image {
        childImageSharp {
          gatsbyImageData(
            width: 100
            aspectRatio: 1.5
            placeholder: BLURRED
          )
        }
      }
      title
      start
      end
    }
  }

  query HomePage {
    markdownRemark(fileAbsolutePath: {regex: "/pages\/home.md/"}) {
      frontmatter {
        de {
          ...HomeFragment
        }
        en {
          ...HomeFragment
        }
      }
    }
    unterschrift: file(relativePath: {eq: "Unterschrift.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 240
          placeholder: BLURRED
        )
      }
    },
    newsMarkdown: markdownRemark(fileAbsolutePath: {regex: "/pages\/news.md/"}) {
      frontmatter {
        de {
          ...HomeNewsFragment
        }
        en {
          ...HomeNewsFragment
        }
      }
    }
    kurseMarkdown: markdownRemark(fileAbsolutePath: {regex: "/pages\/dental-kurse.md/"}) {
      frontmatter {
        de {
          ...HomeKurseFragment
        }
        en {
          ...HomeKurseFragment
        }
      }
    }
  }
`

export default IndexPage
